import request from "@/utils/request";

export function getInfoList(params) {
  return request({
    url: "mandiaoInfo/getNewses",
    method: "get",
    params,
  });
}
export function getActList(params) {
  return request({
    url: "mandiaoCommunity/getActs",
    method: "get",
    params,
  });
}
