import VueRouter from "vue-router";
import ImandiaoHome from "@/pages/ImandiaoHome";
import ImandiaoInfo from "@/pages/ImandiaoInfo";
import ImandiaoService from "@/pages/ImandiaoService";
import ImandiaoAchievement from "@/pages/ImandiaoAchievement";
import ImandiaoCommunity from "@/pages/ImandiaoCommunity";
import ImandiaoDepartment from "@/pages/ImandiaoDepartment";
import ImandiaoCompanySettlement from "@/pages/companyApplication/ImandiaoCompanySettlement";
import MainShow from "@/pages/companyApplication/MainShow";
import SubmitForm from "@/pages/companyApplication/SubmitForm";
import layout from "@/Layout";

export default new VueRouter({
  // base: "/Vue/",
  routes: [
    { path: "/", redirect: "/Home" },
    {
      path: "/",
      name: "layout",
      component: layout,
      children: [
        {
          path: "Home",
          name: "home",
          component: ImandiaoHome,
        },
        {
          path: "Info",
          name: "info",
          component: ImandiaoInfo,
        },
        {
          path: "service",
          name: "service",
          component: ImandiaoService,
        },
        {
          path: "achievement",
          name: "achievement",
          component: ImandiaoAchievement,
        },
        {
          path: "community",
          name: "community",
          component: ImandiaoCommunity,
        },
        {
          path: "department",
          name: "department",
          component: ImandiaoDepartment,
        },
      ],
    },
    {
      path: "/mainShow",
      name: "MainShow",
      component: MainShow,
      children: [
        {
          path: "companySettlement",
          name: "CompanySettlement",
          component: ImandiaoCompanySettlement,
        },
        {
          path: "submitForm",
          name: "SubmitForm",
          component: SubmitForm,
        },
      ],
    },
  ],
});
