<template>
  <div class="container">
    <el-card>
      <div slot="header" class="clearfix">
        <span style="font-size: 18px">企业入驻申请表</span>
      </div>
      <el-form :model="form" label-position="top">
        <el-form-item label="（非必传）以往取得的成果(需上传附件):">
          <div
            v-for="(item, index) in getResultList"
            :key="index"
            class="get-result"
          >
            <el-checkbox v-model="item.checked" :label="item.label">
            </el-checkbox>
            <el-upload
              v-show="item.checked"
              class="upload-demo"
              action="#"
              :data="{ label: item.label }"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :on-success="handleSuccess"
              :http-request="changeFile"
              multiple
              :file-list="fileList"
            >
              <el-button size="mini" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">上传附件最大为3MB</div>
              <!-- <div v-if="item.checked === true && fileList.length === 0">
                请上传文件
              </div> -->
            </el-upload>
          </div>
        </el-form-item>
      </el-form>

      <div class="submit-div">
        <el-button type="primary" class="btn-submit" @click="goBack"
          >上一步</el-button
        >
        <el-button type="primary" class="btn-submit" @click="submitForm"
          >提交企业入驻申请</el-button
        >
      </div>
    </el-card>
    <el-dialog
      title="提交入驻申请成功"
      :visible.sync="centerDialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="30%"
      center
      top="30vh"
    >
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <span style="display: flex; flex-direction: column; align-items: center">
        <i class="el-icon-success my-success-icon"></i>
        <span>非常感谢您的支持和提交！我们将尽快处理您的申请。谢谢！</span>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog"
          >回到官网首页
          <span v-if="showCountdown">{{ countdown }}秒</span>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { deleteFile, deleteApplicationInfo } from "../../api/applySettlement";
export default {
  name: "SubmitForm",

  data() {
    return {
      centerDialogVisible: false,
      id: undefined,
      form: {},
      fileList: [
        // {
        //   name: "food2.jpeg",
        //   url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        // },
      ],
      getResultList: [
        {
          label: "知识产权",
          checked: false,
        },
        {
          label: "荣誉",
          checked: false,
        },
        {
          label: "资质",
          checked: false,
        },
        {
          label: "其他",
          checked: false,
        },
      ],
      fileId: undefined,
      showCountdown: false, // 控制倒计时显示
      countdown: 60, // 倒计时初始值
      countdownInterval: null, // 用于存储倒计时的计时器
    };
  },

  activated() {
    this.id = this.$route.query.id;
  },
  computed: {},

  methods: {
    goBack() {
      deleteApplicationInfo(this.id);
      this.$router.push({
        name: "CompanySettlement",
      });
    },
    closeDialog() {
      this.centerDialogVisible = false;
      // this.$router.push({
      //   name: "CompanySettlement",
      // });
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.history.go(-2);
      } else {
        window.close();
      }

      // window.close();
    },
    handleApplySuccess() {
      this.showCountdown = true; // 显示倒计时
      this.countdownInterval = setInterval(() => {
        this.countdown--; // 倒计时减一
        if (this.countdown <= 0) {
          clearInterval(this.countdownInterval); // 倒计时结束，清除计时器
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            window.history.go(-2);
          } else {
            window.close();
          }
        }
      }, 1300); // 每秒执行一次
    },
    submitForm() {
      this.centerDialogVisible = true;
      this.handleApplySuccess();
    },
    async changeFile(file) {
      console.log(file);
      const isLt2M = file.file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error("上传附件大小不能超过3MB");
        return;
      }
      let fd = new FormData();
      fd.append("file", file.file); // 传文件
      fd.append(
        "achievement",
        new Blob(
          [
            JSON.stringify({
              baseInfoId: this.id,
              achievementType: file.data.label,
            }),
          ],
          {
            type: "application/json;charset=UTF-8",
          }
        )
      );
      // uploadFile(fd)
      //   .then((result) => {
      //     console.log(result);
      //   })
      //   .catch((err) => {});
      const res = await axios.post(
        "https://imandiao.cn/server/applySettlement/uploadFile",
        fd,
        {
          // 因为我们上传了图片,因此需要单独执行请求头的Content-Type
          headers: {
            // 表示上传的是文件,而不是普通的表单数据
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.fileId = res.data;
    },
    handleSuccess(response, file, fileList) {
      fileList[fileList.length - 1].id = this.fileId;
    },
    handleRemove(file, fileList) {
      fileList.push(file);
      deleteFile(file.id).then((result) => {
        if (result > 0) {
          this.$message.success("删除成功");
          fileList.splice(file);
        } else {
          this.$message.error("删除失败");
        }
        return fileList;
      });
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    // beforeUpload(file) {
    //   return isLt2M;
    // },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.el-card {
  width: 50%;
  height: 100vh;
  min-width: 500px;
  padding-bottom: 50px;
}
.el-form {
  padding: 0 15%;
}
.get-result {
  display: flex;
  justify-content: space-between;
}
.el-upload__tip {
  margin-top: -11px;
}
.btn-submit {
  margin-top: 100px;
}
::v-deep .el-upload-list__item-name {
  display: inline-block;
  width: 190px !important;
}
.submit-div {
  width: 50%;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
}
.my-success-icon {
  font-size: 80px;
  color: #67c23a;
  margin-bottom: 8px;
}
@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  ::v-deep .el-dialog {
    width: 90% !important;
  }
  ::v-deep .el-upload-list__item-name {
    display: inline-block;
    width: 100px !important;
  }
  ::v-deep .el-form--label-top {
    .el-form-item__label {
      line-height: 23px !important;
    }
  }
}
</style>