import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import { Message } from "element-ui";
import { Loading } from "element-ui";
// import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/base.css";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import "element-ui/lib/theme-chalk/index.css";
import element from "./plugins/element";
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(element);
Vue.use(Loading.directive);
Vue.component(CollapseTransition.name, CollapseTransition);
new Vue({
  render: (h) => h(App),
  router: router,
  beforeCreate() {
    Vue.prototype.$bus = this;
    Vue.prototype.$message = Message;
  },
}).$mount("#app");
