<template>
  <div id="infoPage">
    <div ref="pageHeader" id="pageHeader">
      <div>
        <h1 v-if="!maneuver">漫调资讯</h1>
        <h1 v-else>漫调社群</h1>
        <div>专业服务&nbsp;以诚为本</div>
      </div>
      <div style="width: 7%">
        <img
          src="../assets/商业化全球.png"
          alt=""
          style="width: 100%; display: block"
        />
      </div>
    </div>
    <div id="pageContent">
      <div class="contentTitle">
        <h2 v-if="!maneuver">资讯中心</h2>
        <h2 v-else-if="maneuver === 1">最新活动</h2>
        <h2 v-else>往期活动</h2>
        <div style="color: rgb(254, 180, 11)">INFORMATION&nbsp;CENTRE</div>
        <div class="shortIntro" style="word-break: break-all">
          漫调e空间&nbsp;&nbsp;一个有温度的自由办公空间
        </div>
      </div>
      <div id="infoWindow" v-for="(infoItem, index) in infoList" :key="index">
        <div class="infoItem">
          <el-image :src="'https://imandiao.cn' + infoItem.imgSrc"></el-image>
          <!-- <img :src="infoItem.imgSrc" alt="" /> -->
          <div class="info-item-right">
            <div class="act-enlist" v-if="maneuver">
              <h3 class="act-title">{{ infoItem.title }}</h3>
              <i
                class="el-icon-edit"
                style="
                  width: 80px;
                  line-height: 25px;
                  cursor: pointer;
                  color: rgb(254, 180, 11);
                "
                @click="handleEnlist(infoItem)"
                v-if="maneuver === 1"
              >
                报名</i
              >
            </div>
            <p class="show-content">
              {{ infoItem.content }}
            </p>
            <div class="infoItem-bottom">
              <a class="button" :href="infoItem.url" target="_blank"
                >了解更多</a
              >
              <span v-if="!maneuver" class="show-time">{{
                infoItem.createTime
              }}</span>
              <span class="show-time" v-else
                >{{ infoItem.startTime }}~{{ infoItem.endTime }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        class="page-right"
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
        :current-page.sync="queryParams.currentPage"
        :page-size.sync="queryParams.pageSize"
      >
      </el-pagination>
    </div>
    <ActEnlist
      :enListDialogVisible.sync="enListDialogVisible"
      :title="title"
      :id="id"
      @clearRow="clearRow"
    ></ActEnlist>
  </div>
</template>

<script>
import ActEnlist from "@/pages/components/ActEnlist";
import { getInfoList, getActList } from "@/api/info";
export default {
  name: "ImandiaoInfo",
  components: {
    ActEnlist,
  },
  data() {
    return {
      toDetail: "http://www.baidu.com",
      maneuver: undefined,
      total: 0,
      queryParams: {
        currentPage: 1,
        pageSize: 6,
      },
      infoList: [],
      enListDialogVisible: false,
      title: "",
      id: undefined,
    };
  },
  methods: {
    handleEnlist(item) {
      this.title = item.title;
      this.id = item.id;
      this.enListDialogVisible = true;
    },
    clearRow() {
      (this.title = ""), (this.id = undefined);
    },
    getList() {
      // 根据不同参数获取信息
      if (!this.maneuver) {
        // 获取资讯信息
        getInfoList(this.queryParams)
          .then((result) => {
            this.infoList = result.content;
            this.total = result.totalNum;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // 获取活动信息
        getActList({ ...this.queryParams, requestType: this.maneuver })
          .then((result) => {
            this.infoList = result.content;
            this.total = result.totalNum;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    skipToHeader() {
      this.$refs.pageHeader.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    changePage() {
      this.$bus.$emit("changePage", "info", false);
    },
  },
  mounted() {
    this.maneuver = this.$route.query.maneuver
      ? this.$route.query.maneuver
      : undefined;
    this.getList();
    this.$bus.$on("info", this.skipToHeader);
  },
  watch: {
    $route: {
      handler() {
        this.maneuver = undefined;
      },
      deep: true,
    },
    maneuver() {
      this.getList();
    },
  },
};
</script>

<style scoped>
#pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(244, 167, 65);
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
}

#pageContent {
  padding-left: 18%;
  padding-right: 18%;
  margin-bottom: 5%;
}
.contentTitle {
  margin-bottom: 5%;
  background-image: url("../assets/shading.jpg");
  background-size: 88%;
  background-repeat: no-repeat;
  padding-bottom: 2%;
  border-bottom: 1px solid rgba(188, 188, 188, 50%);
}

.infoItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5%;
}

.infoItem .el-image {
  width: 30%;
}

.infoItem .info-item-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55%;
}
.act-enlist {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoItem p {
  word-break: break-all;
  text-align: left;
}
.act-title {
  text-align: left;
  font-weight: 600;
}

.button {
  color: white;
  width: 20%;
  height: 20px;
  line-height: 20px;
  padding: 1%;
  border-radius: 15px 15px 15px 15px;
  background-color: rgb(188, 188, 188);
}
.show-content {
  line-height: 2;
  text-indent: 20px;
  font-size: 16px;
  font-weight: 400;
}

.button:hover {
  background-color: rgb(244, 167, 65);
}
.infoItem-bottom {
  display: flex;
  justify-content: space-between;
}
.show-time {
  font-size: 12px;
  line-height: 30px;
  color: #606266;
  margin-right: 5px;
}

@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  #pageContent {
    padding: 0;
  }

  .contentTitle {
    margin: 0 10%;
  }

  #infoWindow {
    margin-top: 5%;
  }

  .button {
    font-size: 12px;
    width: 30%;
    margin-top: 2%;
  }
  .show-content {
    font-size: 11px;
    line-height: 1.5;
  }
  .act-title {
    font-size: 13px;
  text-align: left;
  font-weight: 600;
}
}
</style>