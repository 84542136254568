import request from "@/utils/request";

export function createBaseInfo(data) {
  return request({
    url: "applySettlement/createBaseInfo",
    method: "post",
    data,
  });
}
export function deleteFile(id) {
  return request({
    url: `applySettlement/deleteFile/${id}`,
    method: "delete",
  });
}
export function deleteApplicationInfo(id) {
  return request({
    url: `applySettlement/deleteApplicationInfo/${id}`,
    method: "delete",
  });
}

export function uploadFile(data) {
  return request({
    url: "applySettlement/uploadFile",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
