<template>
  <div>
    <el-dialog
      title="提交个人信息"
      :visible.sync="enListDialogVisible"
      :before-close="handleClose"
      :fullscreen="isFull"
      width="30%"
      :close-on-click-modal="false"
      center
    >
      <el-form
        :model="actForm"
        label-position="right"
        :rules="rules"
        ref="actForm"
        label-width="120px"
        hide-required-asterisk
      >
        <el-form-item label="活动名称:">
          <!-- <el-input
            disabled
            v-model="form.name"
            autocomplete="off"
            size="small"
          ></el-input> -->
          {{ actForm.activityTitle }}
        </el-form-item>
        <el-form-item label="联系人姓名:" prop="personName">
          <el-input
            v-model="actForm.personName"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人电话:" prop="contactNumber">
          <el-input
            v-model="actForm.contactNumber"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称:" prop="enterpriseName">
          <el-input
            v-model="actForm.enterpriseName"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <template v-if="!btnLoading"
          ><el-button @click="handleClose" size="small">取 消</el-button>
          <el-button type="primary" @click="submitForm('actForm')" size="small"
            >确 定</el-button
          ></template
        >
        <el-button
          v-else
          type="primary"
          @click="submitForm('actForm')"
          size="small"
          v-loading="btnLoading"
          >报名中...</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { enrollAct } from "@/api/community";
export default {
  name: "ActEnlist",
  props: {
    enListDialogVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      id: Number,
      require: true,
    },
  },
  data() {
    var phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/;
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写联系电话"));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error("请输入正确的手机号码"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      isFull: false,
      actForm: {
        personName: "",
        contactNumber: undefined,
        enterpriseName: "",
        activityTitle: "",
        activityId: undefined,
      },
      btnLoading: false,
      rules: {
        contactNumber: [
          {
            required: true, // required就是这个框必须填写
            validator: validatePhone, // 规则
            trigger: "blur", // blur失去焦点，事件何时触发
          },
        ],
        personName: [
          {
            required: true, // required就是这个框必须填写
            message: "请填写联系人姓名",
            trigger: "blur", // blur失去焦点，事件何时触发
          },
        ],
        enterpriseName: [
          {
            required: true, // required就是这个框必须填写
            message: "请填写公司名称",
            trigger: "blur", // blur失去焦点，事件何时触发
          },
        ],
      },
    };
  },
  created() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isFull = true;
    } else {
      this.isFull = false;
    }
  },
  watch: {
    title: {
      handler(newV) {
        this.actForm.activityTitle = newV;
      },
    },
    id(newV) {
      this.actForm.activityId = newV;
    },
  },
  methods: {
    handleClose() {
      this.btnLoading = false;
      this.$emit("clearRow");
      this.clearForm();
      this.resetForm("actForm");
      this.$emit("update:enListDialogVisible", false);
    },
    clearForm() {
      this.actForm.personName = "";
      this.actForm.contactNumber = undefined;
      this.actForm.enterpriseName = "";
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          enrollAct(this.actForm).then((result) => {
            if (result === 1) {
              this.handleClose();
              this.$message.success({
                message: "报名成功",
                center: true,
              });
            } else {
              this.$message.error("报名失败");
              this.handleClose();
            }
          });
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
@media screen and (min-device-width: 500px) {
  .el-dialog__wrapper {
    /* width: 35%;
    margin-left: 50%;
    transform: translateX(-50%);
    top: 10vh;
    bottom: 20vh; */
  }
}
</style>