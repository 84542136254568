<template>
  <div id="servicePage">
    <div ref="pageHeader" id="pageHeader">
      <div>
        <h1>漫调服务</h1>
        <div>受人之托&nbsp;忠人之事</div>
      </div>
      <div style="width: 7%;">
        <img src="../assets/商业化全球.png" alt="" style="width: 100%; display: block;">
      </div>
    </div>
    <div id="pageContent">
      <div class="contentTitle">
        <h2>服务板块</h2>
        <div style="color: rgba(244,167,65)">SERVICES</div>
        <div class="shortIntro">中国一站式科技创业服务平台</div>
      </div>
      <div id="serviceContent">
        <div class="servicePart" id="servicePart01">
          <div class="service">
            <img src="../assets/servicePage/cwtg.jpg" alt="">
            <div>公司财务全权代理，专职会计为您提供“代理记账+专业陪教+财务报告+财务规划”的综合财税服务，保姆式财税管家，企业财税零忧虑。
              在餐饮、医疗器械领域有众多服务案例，积累多年的资深经验。</div>
          </div>
          <div class="service">
            <img src="../assets/servicePage/gszc.jpg" alt="">
            <div>一站式公司注册，公司注册免费核名，可为企业提供真实可靠地址，一站式服务无后顾之忧，专业团队专人跟进，快至三天拿证。</div>
          </div>
          <div class="service">
            <img src="../assets/servicePage/kqrk.jpg" alt="">
            <div>领域专家一对一服务，为企业提前规划路径，全程托管高效入库。成功入库可领万元奖励！</div>
          </div>
        </div>
        <div class="servicePart" id="servicePart02">
          <div class="service">
            <img src="../assets/servicePage/mdcs.jpg" alt="">
            <div>我们为您提供全生命周期综合式的财税服务，包括但不限代理记账、注销清算、专项审计、内部清帐、税收筹划、财务托管等多项财税服务。为企业用户提供标准化、智能化财税服务及定制化解决方案，帮助企业用户一直得到专业的财务及运营支撑。</div>
          </div>
          <div class="service">
            <img src="../assets/servicePage/ssch.jpg" alt="">
            <div>提供企业所得税税收筹划、个税税收筹划、增值税税收筹划等多种专业税收筹划方案
              基于漫调的资源整合平台，借助与各地的招商引资合作协议，帮助客户择税收洼地。</div>
          </div>
          <div class="service">
            <img src="../assets/servicePage/ylqx.jpg" alt="">
            <div>为企业提供医疗器械经营许可（二、三类）证书全程申请，领域专项人员一对一服务，全程流程跟踪，申报周期短、通过率行业领先。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImandiaoService",
  methods: {
    skipToHeader() {
      this.$refs.pageHeader.scrollIntoView({
        behavior: "smooth",
        block:"start"
      })
    }
  },
  mounted() {
    this.$bus.$on('service', this.skipToHeader);
  }
}
</script>

<style scoped>
#pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(244,167,65);
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
}

#pageContent {
  margin-top: 5%;
}

.contentTitle {
  margin: 0 18%;
  background-image: url("../assets/shading.jpg");
  background-size: 88%;
  background-repeat: no-repeat;
}

#serviceContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5%;
}

.servicePart {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#servicePart01 {
  margin-right: 5%;
}

#servicePart01>div {
  margin-bottom: 10%;
}

#servicePart02 {
  margin-left: 5%;
  margin-top: 5%;
}

#servicePart02>div {
  margin-top: 10%;
}

.service {
  border-radius: 10px 10px 10px 10px;
  padding: 2%;
  background-color: rgb(258,258,258);
  box-shadow: 2px 2px 3px 1px rgba(58,58,58,50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.service img{
  display: block;
  width: 100%;
  margin-bottom: 3%;
}

.service div {
  width: 90%;
  word-break: break-all;
  text-align: left;
}

@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  .contentTitle {
    margin: 0 10%;
    margin-bottom: 5%;
  }

  #serviceContent {
    padding: 0;
    justify-content: space-around;
  }

  .servicePart {
    width: 46%;
  }

  .service {
    font-size: 14px;
  }

  #servicePart01 {
    margin: 0;
  }

  #servicePart02 {
    margin: 0;
  }

  #servicePart02>div {
    margin-top: 0;
    margin-bottom: 10%;
  }
}
</style>