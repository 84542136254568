<template>
  <div>
    <div class="container">
      <el-card>
        <div slot="header" class="clearfix">
          <span style="font-size: 18px">企业入驻申请表</span>
        </div>
        <el-form
          ref="form"
          :model="form"
          label-width="170px"
          :label-position="labelPostion"
          :rules="rules"
        >
          <el-form-item
            label="企业名称:"
            class="custom-label"
            prop="enterpriseName"
          >
            <el-input
              v-model="form.enterpriseName"
              size="small"
              placeholder="请填写企业名称..."
            ></el-input>
          </el-form-item>
          <el-form-item label="注册地址:" prop="registeredAddress">
            <el-input
              v-model="form.registeredAddress"
              size="small"
              placeholder="请填写注册地址..."
            ></el-input>
          </el-form-item>
          <el-form-item label="申请入驻类型:" prop="settleType">
            <el-select
              v-model="form.settleType"
              placeholder="请选择入驻类型"
              style="width: 100%"
              size="small"
            >
              <el-option label="工位" value="工位"></el-option>
              <el-option label="办公室" value="办公室"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="拟入驻时间:" prop="expectedCheckInDate">
            <el-date-picker
              v-model="form.expectedCheckInDate"
              type="date"
              placeholder="选择入驻时间"
              size="small"
              :editable="false"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="企业法人/项目负责人:" prop="legalEntity">
            <el-input
              v-model="form.legalEntity"
              size="small"
              placeholder="请填写企业法人/项目负责人..."
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人联系电话:" prop="contactNumber">
            <el-input
              v-model="form.contactNumber"
              size="small"
              placeholder="请填写联系电话..."
            ></el-input>
          </el-form-item>
          <el-form-item label="企业员工/团队人数:" prop="employeeNumber">
            <el-input
              v-model.number="form.employeeNumber"
              size="small"
              placeholder="请填写企业员工/团队人数..."
            ></el-input>
          </el-form-item>
          <el-form-item label="其中参加社保人数:">
            <el-input
              v-model.number="form.siNumber"
              size="small"
              placeholder="请填写参加社保人数..."
            ></el-input>
          </el-form-item>
          <el-form-item label="法人身份证号码:" prop="leIdn">
            <el-input
              v-model="form.leIdn"
              size="small"
              placeholder="请填写法人身份证号码..."
            ></el-input>
          </el-form-item>
          <el-form-item label="户藉地址:">
            <el-input v-model="form.residenceAddress" size="small"></el-input>
          </el-form-item>
          <el-form-item label="企业法人/项目负责人身份类型:" prop="checkList">
            <el-checkbox-group v-model="form.checkList">
              <el-checkbox label="毕业5年内的大学生"></el-checkbox>
              <el-checkbox label="登记失业人员"></el-checkbox>
              <el-checkbox label="返乡农民工"></el-checkbox>
              <el-checkbox label="企业高级管理人才"></el-checkbox>
              <el-checkbox label="科学技术人才"></el-checkbox>
              <el-checkbox label="归国留学生"></el-checkbox>
              <el-checkbox label="其他"> </el-checkbox>
            </el-checkbox-group>
            <transition name="el-zoom-in-center">
              <el-input
                v-show="isTypeShow"
                v-model="otherEntityType"
                class="type-other"
                placeholder="请您输入其他身份类型..."
              ></el-input>
            </transition>
          </el-form-item>
          <el-form-item label="所属技术领域:" prop="areaList">
            <el-checkbox-group v-model="form.areaList">
              <el-checkbox label="电子商务/商贸"></el-checkbox>
              <el-checkbox label="新材料"></el-checkbox>
              <el-checkbox label="节能环保"></el-checkbox>
              <el-checkbox label="信息技术"></el-checkbox>
              <el-checkbox label="新能源"></el-checkbox>
              <el-checkbox label="互联网+平台"></el-checkbox>
              <el-checkbox label="智能制造"> </el-checkbox>
              <el-checkbox label="大数据"> </el-checkbox>
              <el-checkbox label="创新科技"> </el-checkbox>
              <el-checkbox label="振兴农业"> </el-checkbox>
              <el-checkbox label="直播带货"> </el-checkbox>
              <el-checkbox label="现代服务业"> </el-checkbox>
              <el-checkbox label="其他"> </el-checkbox>
            </el-checkbox-group>
            <transition name="el-zoom-in-center">
              <el-input
                v-show="isAreaShow"
                v-model="otherTechType"
                class="type-other"
                placeholder="请您输入其他所属领域..."
              ></el-input>
            </transition>
          </el-form-item>
          <el-form-item label="企业属性:" prop="attributeList">
            <el-checkbox-group v-model="form.attributeList">
              <el-checkbox label="科技型入库企业"></el-checkbox>
              <el-checkbox label="创新型中小企业"></el-checkbox>
              <el-checkbox label="高新技术企业"></el-checkbox>
              <el-checkbox label="“专精特新”中小企业"></el-checkbox>
              <el-checkbox label="其他"> </el-checkbox>
            </el-checkbox-group>
            <transition name="el-zoom-in-center">
              <el-input
                v-show="isAttributeShow"
                v-model="OtherEntType"
                class="type-other"
                placeholder="请您输入其他企业属性..."
              ></el-input>
            </transition>
          </el-form-item>
          <el-row>
            <el-col :lg="12" :md="24">
              <el-form-item label="上年度营业收入(万元):  " prop="exTurnover">
                <el-input
                  v-model.number="form.exTurnover"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24">
              <el-form-item label="上年度纳税额(万元):" prop="exTax">
                <el-input
                  v-model.number="form.exTax"
                  size="small"
                ></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-form-item label="主要产品(服务):" prop="productType">
            <el-input
              v-model="form.productType"
              size="small"
              placeholder="请您输入主要产品(服务)..."
            ></el-input>
          </el-form-item>

          <el-form-item label="您希望得到哪些服务:" prop="expectedServiceList">
            <el-checkbox-group v-model="form.expectedServiceList">
              <el-checkbox
                label="工商、代账、社保等一站式事务代理服务"
              ></el-checkbox>
              <el-checkbox label="知识产权服务"></el-checkbox>
              <el-checkbox label="法律服务"></el-checkbox>
              <el-checkbox label="融资服务"></el-checkbox>
              <el-checkbox label="资源对接服务"></el-checkbox>
              <el-checkbox label="人力资源服务"></el-checkbox>
              <el-checkbox label="政策申报服务"></el-checkbox>
              <el-checkbox label="创业活动"></el-checkbox>
              <el-checkbox label="信息推送"></el-checkbox>
              <el-checkbox label="导师辅导"> </el-checkbox>
              <el-checkbox label="其他"></el-checkbox>
            </el-checkbox-group>
            <transition name="el-zoom-in-center">
              <el-input
                v-show="isServiceShow"
                v-model="otherExpectedService"
                class="type-other"
                placeholder="请您输入其他您希望得到的服务"
              ></el-input>
            </transition>
          </el-form-item>
          <el-form-item label="申请人姓名:" prop="applicantName">
            <el-input
              v-model="form.applicantName"
              size="small"
              placeholder="请填写申请人姓名..."
            ></el-input>
          </el-form-item>
          <el-form-item label="申请人联系电话:" prop="applicantContactNumber">
            <el-input
              placeholder="请填写申请人联系电话..."
              v-model="form.applicantContactNumber"
              size="small"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          class="btn-submit"
          @click="submitForm('form')"
          v-loading="nextBtnLoading"
          >下一步</el-button
        >
      </el-card>
    </div>
    <el-backtop target=".app-swiper"></el-backtop>
  </div>
</template>

<script>
import { createBaseInfo } from "../../api/applySettlement";
export default {
  name: "ImandiaoCompanySettlement",
  data() {
    const attributeValidate = (rule, value, callback) => {
      if (
        value.find((item) => {
          return item === "其他";
        }) &&
        this.OtherEntType === ""
      ) {
        callback(new Error("您选择了其他，请填写其他企业属性"));
      } else if (value.length === 0) {
        callback(new Error("请选择企业属性"));
      } else {
        callback();
      }
    };
    const checkListValidate = (rule, value, callback) => {
      if (
        value.find((item) => {
          return item === "其他";
        }) &&
        this.otherEntityType === ""
      ) {
        callback(new Error("您选择了其他，请填写其他身份类型"));
      } else if (value.length === 0) {
        callback(new Error("请选择企业法人/项目负责人身份类型"));
      } else {
        callback();
      }
    };
    const areaListValidate = (rule, value, callback) => {
      if (
        value.find((item) => {
          return item === "其他";
        }) &&
        this.otherTechType === ""
      ) {
        callback(new Error("您选择了其他，请填写其他技术领域"));
      } else if (value.length === 0) {
        callback(new Error("请选择技术领域"));
      } else {
        callback();
      }
    };
    const expectedServiceList = (rule, value, callback) => {
      if (
        value.find((item) => {
          return item === "其他";
        }) &&
        this.otherExpectedService === ""
      ) {
        callback(new Error("您选择了其他，请填写其他服务"));
      } else if (value.length === 0) {
        callback(new Error("请选择您所期望的服务"));
      } else {
        callback();
      }
    };
    return {
      form: {
        siNumber: "",
        enterpriseName: "",
        registeredAddress: "",
        settleType: "",
        expectedCheckInDate: "",
        contactNumber: "",
        employeeNumber: "",
        legalEntity: "",
        applicantName: "",
        applicantContactNumber: "",
        leIdn: "",
        residenceAddress: "",
        entityExperience: "",
        entType: "",
        exTax: 0,
        exTurnover: 0,
        productType: "",
        techField: "",
        expectedService: "",
        expectedServiceList: [],
        checkList: [],
        areaList: [],
        attributeList: [],
      },
      nextBtnLoading: false,
      id: undefined,
      otherEntityType: "",
      otherTechType: "",
      OtherEntType: "",
      otherExpectedService: "",
      validationTimer: null,
      rules: {
        enterpriseName: [
          { required: true, message: "请填写公司名称", trigger: "blur" },
        ],
        registeredAddress: [
          { required: true, message: "请填写公司注册地址", trigger: "blur" },
        ],
        settleType: [
          { required: true, message: "请填写申请入驻类型", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        expectedCheckInDate: [
          { required: true, message: "请选择入驻时间", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        legalEntity: [
          {
            required: true,
            message: "请填写企业法人/项目负责人姓名",
            trigger: "blur",
          },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        contactNumber: [
          {
            required: true,
            message: "请填写手机号码！",
            trigger: "blur",
          },
          {
            pattern: /^((1[3456789]\d{9}))$/,
            message: "请填写正确的手机号码!",
          },
        ],
        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        employeeNumber: [
          {
            required: true,
            message: "请填写企业员工/团队人数",
            trigger: "blur",
          },
          { type: "number", message: "人数必须为数字值" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        leIdn: [
          { required: true, message: "请填写法人身份证号码", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        attributeList: [
          {
            validator: attributeValidate,
            required: true,
            trigger: "blur",
          },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        checkList: [
          {
            validator: checkListValidate,
            required: true,
            trigger: "blur",
          },
        ],
        areaList: [
          { required: true, validator: areaListValidate, trigger: "blur" },
        ],
        exTurnover: [
          { required: true, message: "请填写上年度营业收入", trigger: "blur" },
          { type: "number", message: "收入必须为数字值" },
        ],
        exTax: [
          { required: true, message: "请填写纳税额", trigger: "blur" },
          { type: "number", message: "纳税额必须为数字值" },
        ],
        productType: [
          { required: true, message: "请填写主要产品(服务)", trigger: "blur" },
        ],
        expectedServiceList: [
          { required: true, validator: expectedServiceList, trigger: "blur" },
        ],
        applicantName: [
          { required: true, message: "请填写申请人姓名", trigger: "blur" },
        ],
        applicantContactNumber: [
          { required: true, message: "请填写申请人联系电话", trigger: "blur" },
          {
            pattern: /^((1[3456789]\d{9}))$/,
            message: "请填写正确的手机号码!",
          },
        ],
      },
    };
  },
  computed: {
    isServiceShow() {
      return this.form.expectedServiceList.find((item) => {
        return item === "其他";
      });
    },
    labelPostion() {
      return window.innerWidth <= 500 && window.innerWidth >= 200
        ? "top"
        : "right";
    },
    isTypeShow() {
      return this.form.checkList.find((item) => {
        return item === "其他";
      });
    },
    isAreaShow() {
      return this.form.areaList.find((item) => {
        return item === "其他";
      });
    },
    isAttributeShow() {
      return this.form.attributeList.find((item) => {
        return item === "其他";
      });
    },
  },
  methods: {
    submitForm(formName) {
      this.isTypeShow &&
        this.otherEntityType &&
        this.form.checkList.push(this.otherEntityType);
      this.form.entityExperience = this.form.checkList.join(",");

      this.isAreaShow &&
        this.otherTechType &&
        this.form.areaList.push(this.otherTechType);
      this.form.techField = this.form.areaList.join(",");

      this.isAttributeShow &&
        this.OtherEntType &&
        this.form.attributeList.push(this.OtherEntType);
      this.form.entType = this.form.attributeList.join(",");

      this.isServiceShow &&
        this.otherExpectedService &&
        this.form.expectedServiceList.push(this.otherExpectedService);
      this.form.expectedService = this.form.expectedServiceList.join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.nextBtnLoading = true;
          console.log(this.form);
          createBaseInfo({
            enterpriseName: this.form.enterpriseName,
            leIdn: this.form.leIdn,
            registeredAddress: this.form.registeredAddress,
            settleType: this.form.settleType,
            legalEntity: this.form.legalEntity,
            contactNumber: this.form.contactNumber,
            employeeNumber: this.form.employeeNumber,
            entityExperience: this.form.entityExperience,
            techField: this.form.techField,
            entType: this.form.entType,
            expectedCheckInDate: this.form.expectedCheckInDate,
            exTurnover: Number(this.form.exTurnover),
            exTax: Number(this.form.exTax),
            productType: this.form.productType,
            expectedService: this.form.expectedService,
            applicantName: this.form.applicantName,
            applicantContactNumber: this.form.applicantContactNumber,
          })
            .then((result) => {
              if (result > 0) {
                this.id = result;
                this.$router.push({
                  name: "SubmitForm",
                  query: {
                    id: this.id,
                  },
                });
              } else {
                this.$message.error("申请入住失败,请您退出重新申请");
              }
            })
            .catch((err) => {})
            .finally(() => {
              this.nextBtnLoading = false;
            });
        } else {
          const errorFields = this.$refs[formName].fields.filter(
            (field) => field.validateState === "error"
          );
          if (errorFields.length > 0) {
            errorFields[0].$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
          this.validationTimer = setTimeout(() => {
            this.$refs[formName].clearValidate();
          }, 2000); // 2秒后清除验证状态
          this.validationTimer = null;
          return false;
        }
      });
    },
  },
};
</script>

<style  scoped lang="scss">
.container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.el-card {
  width: 50%;
  min-width: 500px;
  padding-bottom: 50px;
}
.el-input {
  width: 100%;
}
.el-form {
  padding: 0 15%;
}
.btn-submit {
  margin-top: 50px;
  margin-left: 50%;
  transform: translateX(-50%);
}

::v-deep .type-other .el-input__inner {
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
// ::v-deep .el-form-item__error {
//   transition: opacity 2s !important;
// }
</style>