<template>
  <div id="communityPage">
    <div ref="pageHeader" id="pageHeader">
      <div>
        <h1>漫调社群</h1>
        <div>专业服务&nbsp;以诚为本</div>
      </div>
      <div style="width: 7%">
        <img
          src="../assets/商业化全球.png"
          alt=""
          style="width: 100%; display: block"
        />
      </div>
    </div>
    <div id="pageContent">
      <div class="contentTitle">
        <h2>活动预告</h2>
        <div style="color: rgb(254, 180, 11)">ACTIVITY&nbsp;PREVIEW</div>
        <div class="shortIntro" style="word-break: break-all">
          漫调e空间&nbsp;&nbsp;一个有温度的自由办公空间
        </div>
      </div>
      <div id="contentPart02">
        <div class="partTitle">
          <div>
            <div>NEW&nbsp;ACTIVITIES</div>
            <h3>最新活动</h3>
          </div>
          <router-link
            class="more-maneuver"
            :to="{ name: 'info', query: { maneuver: 1 } }"
            >更多 >>></router-link
          >
        </div>
        <div id="picWindow">
          <a
            :href="item.url"
            target="_blank"
            v-for="(item, index) in newActs"
            :key="index"
          >
            <el-image :src="'https://imandiao.cn' + item.imgSrc"></el-image>
          </a>
        </div>
        <div id="newsWindow">
          <div
            class="communityNews"
            v-for="(item, index) in newActs"
            :key="index"
          >
            <div class="communityNews-head">
              <h3>
                <a :href="item.url" target="_blank">{{ item.title }}</a>
              </h3>
              <i class="el-icon-edit sign-up" @click="handleEnlist(item)">
                报名</i
              >
            </div>
            <p style="text-indent: 20px">
              {{ item.content }}
            </p>
            <i class="act-time"
              >活动时间：{{ item.startTime }}~{{ item.endTime }}</i
            >
          </div>
        </div>
      </div>
      <div id="contentPart01">
        <div class="partTitle">
          <div>
            <div>PAST&nbsp;ACTIVITIES</div>
            <h3>往期活动</h3>
          </div>
          <router-link
            class="more-maneuver"
            :to="{ name: 'info', query: { maneuver: 2 } }"
            >更多 >>></router-link
          >
        </div>
        <div id="outerWindow01">
          <div
            class="window01"
            v-for="(oldItem, index) in oldActs"
            :key="index"
          >
            <a :href="oldItem.url">
              <el-image
                :src="'https://imandiao.cn' + oldItem.imgSrc"
              ></el-image>
            </a>
            <h3>
              <a :href="oldItem.url" target="_blank">{{ oldItem.title }}</a>
            </h3>
            <p>{{ oldItem.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <ActEnlist
      :enListDialogVisible.sync="enListDialogVisible"
      :title="title"
      :id="id"
      @clearRow="clearRow"
    ></ActEnlist>
  </div>
</template>

<script>
import ActEnlist from "@/pages/components/ActEnlist";
import { getActs } from "@/api/community";
export default {
  name: "ImandiaoCommunity",
  components: {
    ActEnlist,
  },
  data() {
    return {
      newActs: [],
      enListDialogVisible: false,
      oldActs: [],
      title: "",
      id: undefined,
    };
  },
  methods: {
    clearRow() {
      (this.title = ""), (this.id = undefined);
    },
    handleEnlist(item) {
      this.title = item.title;
      this.id = item.id;
      this.enListDialogVisible = true;
    },
    getList() {
      getActs({
        currentPage: 1,
        pageSize: 2,
        requestType: 1,
      })
        .then((result) => {
          this.newActs = result.content;
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
      getActs({
        currentPage: 1,
        pageSize: 3,
        requestType: 2,
      })
        .then((result) => {
          this.oldActs = result.content;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    skipToHeader() {
      this.$refs.pageHeader.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
  mounted() {
    this.getList();
    this.$bus.$on("community", this.skipToHeader);
  },
};
</script>

<style scoped>
#pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(244, 167, 65);
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
}

a {
  color: black;
}

.contentTitle {
  margin-bottom: 5%;
  background-image: url("../assets/shading.jpg");
  background-size: 88%;
  background-repeat: no-repeat;
}

#pageContent {
  padding-left: 18%;
  padding-right: 18%;
}

#contentPart01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.partTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-left: 5px solid rgb(254, 180, 11);
  padding-left: 0.5%;
  margin-bottom: 5%;
}

.partTitle h3 {
  color: rgb(254, 180, 11);
  text-align: left;
}
.more-maneuver {
  color: #606266;
  margin-right: 10px;
}
#outerWindow01 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.window01 {
  width: 30%;
  margin-bottom: 5%;
}

.window01 > a {
  width: 100%;
}

.window01 img {
  width: 100%;
  display: block;
  margin-bottom: 10%;
  box-shadow: 2px 2px 3px 1px rgba(58, 58, 58, 50%);
}

.window01 > h3 {
  margin-bottom: 5%;
}

.window01 a:hover {
  color: rgb(254, 180, 11);
}

.window01 > p {
  word-break: break-all;
  text-align: left;
  font-size: 14px;
  width: 100%;
}

#contentPart02 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#picWindow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5%;
}

#picWindow a {
  display: flex;
  width: 48%;
  box-shadow: 2px 2px 3px 1px rgba(58, 58, 58, 50%);
}

#picWindow .el-image {
  display: block;
  width: 100%;
}

#newsWindow {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}
.sign-up {
  line-height: 25px;
  cursor: pointer;
  color: rgb(254, 180, 11);
  font-weight: bold;
}
/* .sign-up:hover {
  color: rgb(254, 180, 11);
} */
.communityNews {
  width: 100%;
  border-bottom: 1px dotted black;
  margin-bottom: 4%;
  padding-bottom: 4%;
}
.communityNews:last-child {
  border-bottom: none;
}
.communityNews-head {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 5px;
}

.communityNews > h3 {
  margin-bottom: 1.5%;
}

.communityNews a:hover {
  color: rgb(254, 180, 11);
}

.communityNewsEnd a:hover {
  color: rgb(254, 180, 11);
}

.communityNews > p {
  word-break: break-all;
}
.act-time {
  color: #909399;
  display: flex;
  justify-content: end;
  font-size: 13px;
}

@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  .contentTitle {
    margin: 0 10%;
    margin-bottom: 5%;
  }

  #pageContent {
    padding: 0 1%;
  }

  .partTitle h3 {
    font-size: 15px;
  }

  #outerWindow01 {
    flex-direction: column;
    align-items: center;
  }

  .window01 {
    width: 90%;
  }

  #newsWindow p {
    font-size: 14px;
  }

  #newsWindow h3 {
    font-size: 15px;
  }
}
</style>