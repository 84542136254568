<template>
  <div class="app">
    <ImandiaoHeader />
    <router-view></router-view>
    <ImandiaoFooter />
  </div>
</template>

<script>
import ImandiaoFooter from "@/components/ImandiaoFooter";
import ImandiaoHeader from "@/components/ImandiaoHeader";
export default {
  name: "Layout",
  components: {
    ImandiaoFooter,
    ImandiaoHeader,
  },
  data() {
    return {};
  },

  mounted() {
    this.$bus.$on("changePage", function (name, isPhone) {
      this.$router.push(
        {
          name,
        },
        () => {}
      );
      // }

      if (!isPhone) {
        this.$nextTick(() => {
          this.$bus.$emit(name);
        });
      } else {
        this.$bus.$emit("toOutPart");
      }
    });
  },

  methods: {
    switchPage(name) {
      this.$router.push({
        name: name,
      });
    },
    emitE() {
      this.$bus.$emit("changePage");
    },
  },
};
</script>

<style  scoped>
.app {
  text-align: center;
}

@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  html {
    font-size: 10px;
  }

  h2 {
    font-size: 20px;
  }

  .shortIntro {
    font-size: 13px;
  }
}
</style>