<template>
  <div id="achievementPage">
    <div ref="pageHeader" id="pageHeader">
      <div>
        <h1>漫调荣誉</h1>
        <div>专业服务&nbsp;以诚为本</div>
      </div>
      <div style="width: 7%;">
        <img src="../assets/商业化全球.png" alt="" style="width: 100%; display: block;">
      </div>
    </div>
    <div id="achievement">
      <div class="contentTitle">
        <h2>荣誉展示</h2>
        <div style="color: rgb(254,180,11);">ACHIEVEMENT</div>
        <div class="shortIntro" style="word-break: break-all;">中国一站式科技服务平台</div>
      </div>
      <div id="achievementDisplay">
        <div class="achievementItem">
          <img src="../assets/achievementPage/01.jpg" alt="">
        </div>
        <div class="achievementItem">
          <img src="../assets/achievementPage/02.jpg" alt="">
        </div>
        <div class="achievementItem">
          <img src="../assets/achievementPage/03.jpg" alt="">
        </div>
        <div class="achievementItem">
          <img src="../assets/achievementPage/04.jpg" alt="">
        </div>
        <div class="achievementItem">
          <img src="../assets/achievementPage/05.jpg" alt="">
        </div>
        <div class="achievementItem">
          <img src="../assets/achievementPage/06.jpg" alt="">
        </div>
        <div class="achievementItem">
          <img src="../assets/achievementPage/07.jpg" alt="">
        </div>
        <div class="achievementItem">
          <img src="../assets/achievementPage/08.jpg" alt="">
        </div>
      </div>
    </div>
    <div id="experience">
      <div class="contentTitle">
        <h2>漫调历程</h2>
        <div style="color: rgb(254,180,11);">EXPERIENCE</div>
        <div class="shortIntro" style="word-break: break-all;">中国一站式科技服务平台</div>
      </div>
      <div id="experienceDisplay">
        <div class="experienceItems">
          <div class="experienceItem">
              2015 <br>
              01月漫调e空间正式成立 <br>
              12月获首批“重庆市众创空间”认定
          </div>
          <div class="experienceItem">
            2017 <br>
            07月成为渝北区“上市培育库”企业 <br>
            12月获批为“国家级众创空间”和“重庆市中小企业公共服务示范平台” <br>
            “重庆市信息化建设示范平台”获批为“青创空间” <br>
            北环园区设立
          </div>
          <div class="experienceItem">
            2019 <br>
            09月纳入重庆市“专精特新企业培育库” <br>
            09月自主研发的“智慧办公应用软件”和“共享办公管理系统”通过“知识产权管理体系认证” <br>
            09月获批为“众创空间知识产权服务工作站” <br>
            嘉州协信园区、二郎园区成立
          </div>
          <div class="experienceItem">
            2021 <br>
            01月获批为“重庆市科技企业孵化器” <br>
            08月获批为“2020年度A级纳税企业” <br>
            12月获批为“国家中小企业公共服务示范平台”
          </div>
        </div>
        <div id="decoration">
          <div><img src="../assets/1.jpg" alt="" style="width: 100%;"></div>
        </div>
        <div class="experienceItems" style="display: flex; flex-direction: column-reverse;">
          <div class="experienceItem">
            2022 <br>
            07月获批为“重庆市渝北区服务业发展成长企业”<br>
            10月获批为2021年度“A级纳税企业”和“高新技术企业”<br>
            12月获批为“2022年度重点软件产业楼宇”<br>
            12月作为重庆提档升级园区写入“渝北区两会工作报告”<br>
            12月金星园区筹备建设
          </div>
          <div class="experienceItem">
            2020 <br>
            10月获“中小企业上云实施智能制造成效奖励” <br>
            10月获评“中国百家特色孵化载体 <br>
            12月获评“科技创业孵化贡献奖” <br>
            綦江园区成立
          </div>
          <div class="experienceItem">
            2018 <br>
            08月受邀参加首届智博会 <br>
            12月获批为“重庆市创业孵化基地” <br>
            杨家坪园区、汽博园区成立
          </div>
          <div class="experienceItem">
            2016 <br>
            02月成立大数据公司,开启智慧孵化布局 <br>
            04月在重庆股份转让中心正式挂牌，成为重庆市众创空间联盟理事单位 <br>
            龙塔园区成立
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImandiaoAchievement",
  methods: {
    skipToHeader() {
      this.$refs.pageHeader.scrollIntoView({
        behavior: "smooth",
        block:"start"
      });
    }
  },
  mounted() {
    this.$bus.$on('achievement', this.skipToHeader);
  }
}
</script>

<style scoped>
#pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(244, 167, 65);
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
}

#achievement {
  padding-left: 18%;
  padding-right: 18%;
  margin-bottom: 5%;
}

.contentTitle {
  margin-bottom: 5%;
  background-image: url("../assets/shading.jpg");
  background-size: 88%;
  background-repeat: no-repeat;
}

#achievementDisplay {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.achievementItem {
  width: 21%;
  margin-bottom: 5%;
  box-shadow: 2px 2px 3px 1px rgba(58, 58, 58, 50%);
}

.achievementItem img {
  display: block;
  width: 100%;
}

#experience {
  padding-left: 18%;
  padding-right: 18%;
  margin-bottom: 5%;
}

#experienceDisplay {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#decoration {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 8%;
}

.experienceItems {
  width: 40%;
}

.experienceItem {
  border-radius: 10px 10px 10px 10px;
  background-color: white;
  box-shadow: 1px 1px 3px 1px rgba(58,58,58,50%);
  word-break: break-all;
  width: 100%;
  height: 18%;
  margin-top: 8%;
  padding: 1%;
  font-size: 15px;
  display:flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  #achievement {
    padding: 0;
  }

  .contentTitle {
    margin: 0 10%;
    margin-bottom: 5%;
  }

  .achievementItem {
    width: 40%;
  }

  #experience {
    padding: 0;
  }

  .experienceItems {
    width: 43%;
  }

  .experienceItem {
    font-size: 10px;
    height: auto;
  }

  #decoration {
    display: none;
  }
}

</style>