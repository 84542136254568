<template>
  <div id="departmentPage">
    <div ref="pageHeader" id="pageHeader">
      <div>
        <h1>漫调园区</h1>
        <div>科技改变创业</div>
      </div>
      <div style="width: 7%">
        <img
          src="../assets/商业化全球.png"
          alt=""
          style="width: 100%; display: block"
        />
      </div>
    </div>
    <div id="pageContent">
      <div class="contentTitle">
        <h2>漫调空间</h2>
        <div style="color: rgb(254, 180, 11)">MANDIAO&nbsp;SPACE</div>
        <div class="shortIntro" style="word-break: break-all">
          漫调e空间&nbsp;&nbsp;一个有温度的自由办公空间
        </div>
      </div>
      <div id="contentPart">
        <div id="departmentOverview">
          <img
            src="../assets/departmentPage/mainPic.jpg"
            alt=""
            style="width: 36%"
          />
          <div id="overviewText">
            <div class="partTitle">
              <div>ABOUT&nbsp;OUR&nbsp;DEPARTMENT</div>
              <h3>漫调八大优势</h3>
            </div>
            <p style="word-break: break-all">
              产业聚焦：重庆市重点软件楼宇 <br />
              拎包入住：办公家具网络空调全配套 <br />
              管家服务：行政后勤全程无忧 <br />
              租期灵活：酒店式预定一天起租 <br />
              动态变换：根据企业发展需要灵活变换办公面积 <br />
              连锁办公：主城全覆盖入驻一园共享全城 <br />
              服务配套：自营服务团队一站式企业服务外包 <br />
              租金减免：企业服务全额抵扣租金
            </p>
          </div>
        </div>
        <div
          class="departmentDetail"
          @mousemove="clearTimer"
          @mouseout="startTimer"
        >
          <div class="btn-left">
            <i class="el-icon-arrow-left" @click="changeDep(1)"></i>
          </div>
          <div class="btn-right">
            <i class="el-icon-arrow-right" @click="changeDep(-1)"></i>
          </div>
          <div class="partTitle">
            <div>OUR&nbsp;DEPARTMENT</div>
            <h3>{{ depData[depIndex].name }}园区</h3>
          </div>
          <div id="certainDepartment">
            <img :src="depData[depIndex].img" alt="" />
            <div>
              <p>
                {{ depData[depIndex].info }}
              </p>
            </div>
          </div>
          <div id="picDisplay">
            <img :src="depData[depIndex].images[0]" alt="" style="width: 30%" />
            <img :src="depData[depIndex].images[1]" alt="" style="width: 30%" />
            <img :src="depData[depIndex].images[2]" alt="" style="width: 30%" />
          </div>
          <div id="managerInfo">
            <img :src="depData[depIndex].manager.QRcodeAddress" alt="" />
            <div>
              <p>店长：{{ depData[depIndex].manager.name }}</p>
              <p>联系电话：{{ depData[depIndex].manager.phoneNumber }}</p>
              <p>园区地址：{{ depData[depIndex].manager.address }}</p>
            </div>
          </div>
          <div style="width: 100%; display: flex; justify-content: center">
            <a class="button" :href="depData[depIndex].link" target="_blank"
              >了解详情</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getParkArea } from "@/api/parkArea";
export default {
  name: "ImandiaoDepartment",
  data() {
    return {
      depData: [],
      depIndex: 0,
      time: null,
    };
  },
  methods: {
    clearTimer() {
      clearInterval(this.time);
    },
    startTimer() {
      this.timer();
    },
    changeDep(num) {
      this.depIndex += num;
      if (this.depIndex < 0) this.depIndex = this.depData.length - 1;
      if (this.depIndex > this.depData.length - 1) this.depIndex = 0;
    },
    timer() {
      this.time = setInterval(() => {
        if (this.depIndex < this.depData.length - 1) this.depIndex++;
        else this.depIndex = 0;
      }, 5000);
    },
    skipToHeader() {
      this.$refs.pageHeader.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    getParkArea() {
      getParkArea({ current: 1, size: 15 }).then((res) => {
        this.depData = res.data.content.map((item) => {
          return {
            name: item.name,
            info: item.introduction,
            img: JSON.parse(item.pic)[0],
            images: JSON.parse(item.pic).slice(1),
            link: item.detailUrl,
            manager: {
              name: item.managerName,
              phoneNumber: item.contactNo,
              QRcodeAddress: item.wechatCode,
              address: item.address,
            },
          };
        });
      });
    },
  },
  created() {
    this.timer();
    this.$bus.$on("department", this.skipToHeader);
    this.getParkArea();
  },
};
</script>

<style scoped>
#pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(244, 167, 65);
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
}

.contentTitle {
  margin-bottom: 5%;
  background-image: url("../assets/shading.jpg");
  background-size: 88%;
  background-repeat: no-repeat;
}

#pageContent {
  padding-left: 18%;
  padding-right: 18%;
  margin-bottom: 5%;
}

#contentPart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partTitle {
  border-left: 5px solid rgb(254, 180, 11);
  padding-left: 0.5%;
  margin-bottom: 2%;
}

.partTitle > h3 {
  color: rgb(254, 180, 11);
}

#departmentOverview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5%;
}

#overviewText {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  width: 58%;
}

.departmentDetail {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 3%;
  padding-bottom: 2%;
  box-shadow: 2px 2px 3px 1px rgba(58, 58, 58, 50%);
}
.btn-left {
  position: absolute;
  left: -10%;
  top: 500px;
  /* transform: translateY(-50%); */
  width: 20%;
  height: 20%;
}
.btn-right {
  position: absolute;
  right: -23%;
  top: 500px;
  width: 20%;
  height: 20%;
}
.el-icon-arrow-left,
.el-icon-arrow-right {
  font-size: 75px;
  color: #a8a8a8bd;
  cursor: pointer;
}
.el-icon-arrow-left:hover,
.el-icon-arrow-right:hover {
  color: #939292;
}

#certainDepartment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}

#certainDepartment img {
  width: 36%;
}

#certainDepartment div {
  width: 58%;
}

#certainDepartment p {
  word-break: break-all;
  text-indent: 2em;
  margin-right: 3%;
}

#picDisplay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}

#managerInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 3%;
}

#managerInfo > img {
  display: block;
  width: 20%;
}

#managerInfo > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(0.5rem + 0.8vw);
}

.button {
  color: white;
  text-align: center;
  width: 15%;
  padding: 1%;
  border-radius: 15px 15px 15px 15px;
  background-color: rgb(188, 188, 188);
  box-shadow: 2px 2px 3px 1px rgba(58, 58, 58, 50%);
}

.button:hover {
  background-color: rgb(244, 167, 65);
}

@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  .contentTitle {
    margin: 0 10%;
    margin-bottom: 5%;
  }

  #pageContent {
    padding: 0 1%;
    margin-bottom: 1%;
  }

  #overviewText {
    width: 100%;
  }

  .partTitle {
    font-size: 14px;
  }

  #departmentOverview {
    width: 100%;
  }

  #departmentOverview > img {
    display: none;
  }

  .departmentDetail {
    box-shadow: 0px 0px 0px 0px rgba(58, 58, 58, 50%);
  }
  .btn-left {
    position: absolute;
    left: 0;
    top: 200px;
    /* transform: translateY(-50%); */
    width: 9%;
    height: 10%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 200px;
    width: 9%;
    height: 10%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .el-icon-arrow-left,
  .el-icon-arrow-right {
    font-size: 20px;
    color: white;
    font-weight: 700;
  }
  .el-icon-arrow-left:hover,
  .el-icon-arrow-right:hover {
    color: white;
  }
  #overviewText > p {
    font-size: 17px;
  }

  #certainDepartment {
    flex-direction: column;
    align-items: center;
  }

  #certainDepartment img {
    width: 100%;
    margin-bottom: 5%;
  }

  #certainDepartment div {
    width: 100%;
  }

  #certainDepartment p {
    font-size: 15px;
    margin: 0 2%;
  }

  #managerInfo > div {
    font-size: 0.8rem;
  }

  .button {
    width: 20%;
    padding: 2%;
    font-size: 14px;
  }
}
</style>