<template>
  <div class="app-swiper">
    <div class="head-container">
      <img src="../../assets/aimandiaoLogoH.png" alt="" />
    </div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "MainShow",

  data() {
    return {};
  },

  created() {
    document.title = "企业入驻申请";
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.app-swiper {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.head-container {
  width: 100%;
  height: 90px;
  padding-left: 10%;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
}
.head-container img {
  width: 12%;
}
.container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
@media (max-width: 1350px) {
  .head-container img {
    width: 180px;
  }
}
@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  .head-container {
    padding-left: 0;
  }
  .container {
    justify-content: flex-start;
    padding-top: 10px;
  }
  ::v-deep .el-card {
    width: 100vw;
    min-width: 100vw !important;
  }
  ::v-deep .el-card__body {
    padding: 20px 0 0 0 !important;
  }
  .el-form {
    padding: 0 10%;
  }
  ::v-deep .el-form--label-top {
    .el-form-item__label {
      padding: 0 !important;
      line-height: 0 !important;
      font-size: 16px;
    }
  }
  ::v-deep .el-input__inner {
    width: 100% !important;
  }
}
</style>