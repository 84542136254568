import request from "@/utils/request";

export function getVideo() {
  return request({
    url: "mandiaoHome/getVideo",
    method: "get",
  });
}
export function getSwiper() {
  return request({
    url: "mandiaoHome/getRollingPics",
    method: "get",
  });
}
