<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// document.addEventListener("touchstart", function (e) {
//   console.log("1", e.touches.length);
//   if (e.touches.length > 1) {
//     e.preventDefault();
//   }
// });
// document.addEventListener("gesturestart", function (e) {
//   console.log("2");
//   e.preventDefault();
// });
export default {
  name: "App",
  methods: {},
  mounted() {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
