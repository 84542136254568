// import Vue from "vue";
import {
  Button,
  Pagination,
  Image,
  Dialog,
  Form,
  FormItem,
  Input,
  Card,
  DatePicker,
  Col,
  Row,
  Backtop,
  CheckboxGroup,
  Checkbox,
  Upload,
  Select,
  Option,
} from "element-ui";
const element = {
  install: function (Vue) {
    Vue.use(Button);
    Vue.use(Pagination);
    Vue.use(Image);
    Vue.use(Dialog);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Input);
    Vue.use(Card);
    Vue.use(DatePicker);
    Vue.use(Col);
    Vue.use(Row);
    Vue.use(Backtop);
    Vue.use(CheckboxGroup);
    Vue.use(Checkbox);
    Vue.use(Upload);
    Vue.use(Select);
    Vue.use(Option);
  },
};
export default element;
