<template>
  <div id="homePage">
    <div id="introduction">
      <div class="contentTitle">
        <h2>关于我们</h2>
        <div style="color: rgb(254, 180, 11)">ABOUT&nbsp;US</div>
        <div class="shortIntro">中国一站式科技创业服务平台</div>
      </div>
      <div id="detail" style="margin-top: 3%">
        <!--        <img src="../assets/departmentPage/yangjiaping.jpg" alt="">-->
        <!-- :poster="'https://imandiao.cn' + videoData.posterAddress" -->
        <video
          :poster="'https://imandiao.cn' + videoData.posterAddress"
          :src="'https://imandiao.cn' + videoData.videoAddress"
          controls
          autoplay
          muted
          loop
        ></video>
        <div>
          <p style="margin-bottom: 2%">
            漫调e空间，以连锁智慧办公园区为载体，以科技型企业发展路径企业服务为抓手，建设基于企业大数据的数字创业生态助力中小微科技型企业在供应链、投融资和技术成果转移转化等方面受益的商业社交平台和资源配置平台，用科技改变创业。
          </p>
          <p>
            漫调e空间在重庆扎根九年，一直致力于为中国创业者提供优质的办公室租赁服务和全方位的企业增值服务。
            截止2023年7月，漫调在重庆市内拥有八大园区，办公点覆盖渝北区、江北区、九龙坡区、两江新区、綦江区五大区。园区内茶水间、会议室、洽谈室等配套设施一应俱全，入驻后全免费使用！除了基础的办公设施外还有一整套的智能办公系统打造数字化智慧园区，创业者可根据自己的需求自由选择办公室或共享办公位租赁。目前是重庆地区规模较大、企业粘性强的行业头部企业、拥有完整的企业服务链条。
          </p>
        </div>
      </div>
    </div>
    <div id="business">
      <h2>业务范围</h2>
      <div>PRODUCT</div>
      <div>办公租赁、工商注册、财税代理、知识产权、科技服务、创业孵化</div>
      <div id="productIcon">
        <div class="icon"><img src="../assets/商业化全球.png" alt="" /></div>
        <div class="icon"><img src="../assets/联盟链.png" alt="" /></div>
        <div class="icon"><img src="../assets/图表柱图.png" alt="" /></div>
        <div class="icon"><img src="../assets/证照执照.png" alt="" /></div>
        <div class="icon"><img src="../assets/应用场景.png" alt="" /></div>
      </div>
    </div>
    <div id="cooperationCustomers">
      <h2>服务企业展示</h2>
      <div style="color: rgb(254, 180, 11)">COOPERATIVE&nbsp;CUSTOMERS</div>
      <div id="displayWindow">
        <div class="cooCustomersWindow">
          <div class="customerIcons01">
            <div class="customerIcon">
              <img src="../assets/homePage/元气森林.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/宏林机电.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/尊字.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/红月亮.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/景睿旅游.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/元气森林.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/宏林机电.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/尊字.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/红月亮.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/景睿旅游.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="cooCustomersWindow">
          <div class="customerIcons02">
            <div class="customerIcon">
              <img src="../assets/homePage/智慧星光.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/福禄.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/震林文化.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/费列罗.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/钟薛高.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/智慧星光.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/福禄.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/震林文化.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/费列罗.jpg" alt="" />
            </div>
            <div class="customerIcon">
              <img src="../assets/homePage/钟薛高.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVideo } from "@/api/home";
export default {
  name: "ImandiaoHome",
  data() {
    return {
      videoData: {
        videoAddress: "",
        posterAddress: "",
      },
    };
  },
  created() {
    getVideo()
      .then((res) => {
        this.videoData = res;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
#introduction {
  margin-top: 5%;
}

.contentTitle {
  margin: 0 18%;
  background-image: url("../assets/shading.jpg");
  background-size: 88%;
  background-repeat: no-repeat;
}

#detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/*#detail img {*/
/*  width: 36%;*/
/*  display: block;*/
/*  margin-right: 3%;*/
/*}*/

#detail video {
  width: 36%;
  display: block;
  margin-right: 3%;
}

#detail > div {
  width: 40%;
}

#detail p {
  width: 100%;
  word-break: break-all;
  text-align: left;
  text-indent: 2em;
}

#business {
  /*border: 1px solid black;*/
  background-color: rgba(243, 118, 40, 90%);
  margin-top: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  color: white;
}

#productIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
}

.icon {
  margin-top: 2%;
}

.icon img {
  width: 36%;
}

#displayWindow {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#cooperationCustomers {
  margin-top: 5%;
  margin-bottom: 2%;
}

.cooCustomersWindow {
  /*border: 1px solid black;*/
  overflow: hidden;
}

.customerIcons01 {
  display: flex;
  width: 300%;
  position: relative;
  animation: rollForward 50s linear infinite;
  margin-top: 5%;
  margin-bottom: 3%;
}

.customerIcons02 {
  display: flex;
  width: 300%;
  position: relative;
  animation: rollBack 50s linear infinite;
  margin-top: 3%;
  margin-bottom: 3%;
}

.customerIcon {
  display: flex;
  width: 100%;
}

.customerIcon > img {
  /*border: 1px solid black;*/
  box-shadow: 1px 1px 3px 1px rgba(58, 58, 58, 50%);
  display: block;
  width: 36%;
}

@keyframes rollForward {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes rollBack {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}

@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  .contentTitle {
    margin: 0 10%;
  }

  #detail {
    flex-direction: column;
    font-size: 15px;
  }

  /*#detail>img {*/
  /*  width: 100%;*/
  /*  display: block;*/
  /*  margin-right: 0;*/
  /*}*/

  #detail > video {
    width: 100%;
    display: block;
    margin-right: 0;
  }

  #detail > div {
    width: 90%;
    margin-top: 5%;
  }

  #business > div:nth-of-type(2) {
    font-size: 12px;
  }

  #displayWindow {
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .customerIcon > img {
    width: 60%;
  }
}
</style>