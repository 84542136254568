<template>
  <div id="footerPage">
    <div id="outPart">
      <img src="../assets/footerPage/mandiaozhongchuang.jpg" alt="">
      <div class="part02">
        <p>电话：023-86228000&nbsp;&nbsp;邮箱：mandiao@imandiao.cn</p>
        <p>微信公众号：漫调众创&nbsp;&nbsp;地址：重庆市两江新区星光大道60号金星科技大厦C栋5楼</p>
          <div style="margin: 0 auto; padding-top: 2%; display: flex; flex-direction: row; font-size: 12px">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50011202503901"
               style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../assets/footerPage/beian.jpg" style="float:left;"/>
              <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">渝公网安备
                50011202503901号</p></a>
          </div>
      </div>
      <img src="../assets/aimandiaoLogoHWhite.pic.jpg" alt="mandiaoLogo" style="width: 17%;">
    </div>
  </div>
</template>

<script>
export default {
  name: "ImandiaoFooter",
  mounted() {
    this.$bus.$on('e01', function () {
      console.log('emit:e01');
    })
  }
}
</script>

<style scoped>
#footerPage {
  background-color: black;
}

#outPart {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 15px;
}

.part02 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#outPart img:nth-of-type(1) {
  width: 7%;
  display: block;
}

@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  #outPart {
    flex-direction: column;
    padding-top: 2%;
  }

  .part02 {
    color: rgba(258, 258, 258, 77%);
    font-size: 13px;
    padding: 10px 10px;
  }

  #outPart>img:nth-of-type(1) {
    width: 20%;
    display: block;
  }

  #outPart img:nth-of-type(2) {
    display: none;
  }
}
</style>