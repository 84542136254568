<template>
  <div ref="outPart" id="outPart">
    <div id="Nav">
      <img src="../assets/aimandiaoLogoH.png" alt="mandiaoLogo" />
      <img
        class="onPhone"
        v-show="!phoneTags"
        @click="phoneTags = !phoneTags"
        src="../assets/headerPage/menu.jpg"
        alt=""
      />
      <img
        class="onPhone"
        v-show="phoneTags"
        @click="phoneTags = !phoneTags"
        src="../assets/headerPage/menuRevert.png"
        alt=""
      />
      <div id="phoneTags" class="onPhone" v-show="phoneTags">
        <div class="tag-item" @click="toPage('home', true)">首页</div>
        <div class="tag-item" @click="toPage('achievement', true)">
          漫调荣誉
        </div>
        <div class="tag-item" @click="toPage('department', true)">漫调园区</div>
        <div class="tag-item" @click="toPage('service', true)">漫调服务</div>
        <div class="tag-item" @click="toPage('info', true)">漫调资讯</div>
        <div class="tag-item" @click="toPage('community', true)">漫调社群</div>
        <a class="tag-item" href="dataMap/index.html" target="_blank"
          >营商地图</a
        >
        <div class="tag-item" @click="handleCheckIn">申请入驻</div>
      </div>
      <div id="tags">
        <div class="tag" @click="toPage('home', false)">首页</div>
        <div class="tag" @click="toPage('achievement', false)">漫调荣誉</div>
        <div class="tag" @click="toPage('department', false)">漫调园区</div>
        <div class="tag" @click="toPage('service', false)">漫调服务</div>
        <div class="tag" @click="toPage('info', false)">漫调资讯</div>
        <div class="tag" @click="toPage('community', false)">漫调社群</div>
        <a class="tag tag-map" href="dataMap/index.html" target="_blank"
          >营商地图</a
        >
        <div class="tag" @click="handleCheckIn">申请入驻</div>
      </div>
    </div>
    <div id="view">
      <div class="overview-images">
        <div class="overview-img" ref="overview-img">
          <!-- <div><img src="../assets/background_mandiaoGate.jpg" alt="" /></div>
          <div><img src="../assets/background_mandiaoLobby.jpg" alt="" /></div>
          <div>
            <img src="../assets/background_mandiaoCorridor.jpg" alt="" />
          </div> -->
          <div v-for="(itemImg, index) in swiperList" :key="index">
            <img :src="itemImg" alt="" />
          </div>
        </div>
      </div>
      <ul class="pots">
        <li
          v-for="i in swiperList.length"
          :key="i - 1"
          ref="pot"
          class="pot"
        ></li>
      </ul>
    </div>
    <div id="sideInfo">
      <h1 style="margin-bottom: 2%">快乐工作&nbsp;&nbsp;漫调生活</h1>
      <p style="word-break: break-all; text-align: left">
        漫调e空间是国家备案众创空间，以构建“有温度的自由办公空间”为目标，以“低成本、高价值、便利化”
        的办公空间为核心，建设基于社群的商业社交平台和资源配置平台。
      </p>
      <!--      <div class="toDetail">了解详情</div>-->
    </div>
  </div>
</template>

<script>
import { getSwiper } from "@/api/home";
export default {
  name: "ImandiaoHeader",
  data() {
    return {
      currentPagePosition: 0,
      pageNum: 0,
      phoneTags: true,
      time: "",
      images: null,
      swiperList: [],
    };
  },
  computed: {
    // images() {
    //   return document.querySelector(".overview-img");
    // },
    pageWidth() {
      return this.images.clientWidth / this.pageNum;
    },
  },
  methods: {
    getList() {
      getSwiper().then((result) => {
        this.swiperList = result.data.map((item) => {
          return item.url;
        });
        this.images.style.width = this.swiperList.length * 100 + "%";
        this.pageNum = this.swiperList.length;
      });
    },
    handleCheckIn() {
      let { href } = this.$router.resolve({
        name: "CompanySettlement",
      });
      window.open(href, "_blank");
    },
    slide(distance) {
      this.images.style.webkitTransform =
        "translate3d(" + distance + "px, 0, 0)";
    },
    timer() {
      this.time = setInterval(() => {
        this.images.style.transition = ".5s";
        let distance =
          (this.currentPagePosition - this.pageWidth) %
          (this.pageWidth * this.pageNum);
        if (this.$refs.pot[-this.currentPagePosition / this.pageWidth]) {
          this.$refs.pot[
            -this.currentPagePosition / this.pageWidth
          ].style.background = "none";
        }
        this.slide(distance);
        this.currentPagePosition = distance;
        if (this.$refs.pot[-this.currentPagePosition / this.pageWidth]) {
          this.$refs.pot[
            -this.currentPagePosition / this.pageWidth
          ].style.background = "white";
        }
      }, 3000);
    },
    toPage(name, isPhone) {
      this.$bus.$emit("changePage", name, isPhone);
    },
    skipTop() {
      this.$refs.outPart.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
  created() {
    this.$bus.$on("toOutPart", this.skipTop);
    this.getList();

    this.$nextTick(() => {
      this.images = this.$refs["overview-img"];

      this.timer();
    });
  },
};
</script>

<style scoped>
.onPhone {
  display: none;
}

#outPart {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
}

#Nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 999;
  background-color: rgba(258, 258, 258, 30%);
}

#Nav img {
  width: 12%;
}

#tags {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.tag {
  border-bottom: 5px solid rgba(243, 118, 40, 0);
  padding-bottom: 3px;
}

.tag:hover {
  border-bottom: 5px solid rgba(243, 118, 40, 90%);
  cursor: pointer;
}
.tag-map {
  color: black;
}
#view {
  position: relative;
}

.overview-images {
  /*width: 100%;*/
  position: relative;
  overflow: hidden;
}

.overview-img {
  display: flex;
  transform: translate3d(0, 0, 0);
  /* width: 400%; */
}

.overview-img div {
  width: 100%;
}

.overview-img img {
  width: 100%;
  max-width: 100%;
  display: block;
}

.pots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5%;
  width: 100%;
  z-index: 999;
}

.pot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 1px solid white;
  background: none;
  margin-left: 10px;
  margin-right: 10px;
}

.pot:nth-of-type(1) {
  background-color: white;
}

#sideInfo {
  width: 30%;
  position: absolute;
  top: 36%;
  color: rgba(258, 258, 258, 77%);
  background-color: rgba(294, 167, 55, 40%);
  /*background-color: rgba(158,158,158,36%);*/
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 3%;
  box-shadow: 2px 2px 3px 1px rgba(58, 58, 58, 50%);
}

#sideInfo:hover {
  color: rgba(258, 258, 258);
  background-color: rgba(294, 167, 55);
}

.toDetail {
  margin-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 10px 10px 10px 10px;
  background-color: rgb(244, 167, 65);
  box-shadow: 2px 2px 3px 1px rgba(58, 58, 58, 50%);
}

@media screen and (min-device-width: 200px) and (max-device-width: 500px) {
  #Nav {
    position: fixed;
    justify-content: space-between;
  }

  #Nav img:nth-of-type(1) {
    width: 25%;
    margin-left: 2%;
  }

  #Nav img:nth-of-type(2) {
    display: block;
    width: 10%;
    margin-right: 2%;
    box-shadow: 0 0 4px black;
  }

  #Nav img:nth-of-type(3) {
    display: block;
    width: 10%;
    margin-right: 2%;
    box-shadow: 0 0 4px black;
  }

  #tags {
    display: none;
  }

  #phoneTags {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 55px;
    right: 0;
  }

  #phoneTags .tag-item {
    color: white;
    background-color: rgba(158, 158, 158, 67%);
    box-shadow: 1px 1px 2px black;
    margin-bottom: 30px;
    padding: 3px 0;
    border-radius: 15px 15px 15px 15px;
    font-size: 15px;
    width: 100%;
  }

  #sideInfo {
    display: none;
  }
}
</style>